import { Component, OnInit,  } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup,  Validators } from '@angular/forms';
import { AccountService } from '@app/_services/account.service';
import { AlertService } from '@app/_services/alert.service';
import { ConfigDataService } from '@app/_services/config-data.service'
import { MakeModel } from '@app/_models/makeModel';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { StateCity } from '@app/_models/stateCity';
import { PlanDetails } from '@app/_models/planDetails';
import { User } from '@app/_models';
import { DealerService } from '@app/_services/dealer-service';
import { Dealer } from '@app/_models/dealer';
import { Certificate } from '@app/_models/certificate';
import { CertificateService } from '@app/_services/certificate.service'
import { first } from 'rxjs/internal/operators/first';
import { CertificateResponse } from '@app/_models/certificateResponse';
import { ActivatedRoute, Router } from '@angular/router';
import { DealerModelConfig } from '@app/_models/dealerModelConfig';
import { DealerModelPrice } from '@app/_models/dealerModelPrice';



@Component({
  selector: 'app-add-certificate',
  templateUrl: './add-certificate.component.html',
  styleUrls: ['./add-certificate.component.less']
})
export class AddCertificateComponent implements OnInit {
  certficateForm: UntypedFormGroup;
  showGstAmount = true;
  loading = false;
  isBalanceLow = false;
  firstTime = true;
  submitted = false;
  dealer = new Dealer();
  makeModel: MakeModel[];
  configMakeModel: MakeModel[];
  models: string[];
  cities: string[];
  financeCompanies: string[] ;
  selectedFinanceCompanies: string[];
  stateCity: StateCity[];
  plans: PlanDetails[];
  // twoWheelerPlans: PlanDetails[];
  // nonTwoWheelerPlans: PlanDetails[];
  maxAmount = 99999;
  minAmount = 0;
  showFinance = false;
  selectedMakeModel: MakeModel;
  selectedPlan= new PlanDetails();
  selectedFinance:string;
  nameRegexp = new RegExp("",'i');
  startDateModel: NgbDateStruct;
  dobString: string;
  customerDob: string;
  runningBalance: number;
  types = ["New", "Old"];
  genders = ["Male", "Female"];
  clientTypes = ["Individual", "Corporate"];
  years: number[];
  relationships = ["Spouse","Husband","Wife", "Father", "Mother", "Son", "Daughter", "Brother", "Sister","Grandson","Granddaughter", "Other"];
  registrtionNumberValidation = [];
  certificateResponse: CertificateResponse;
  planAmount: any;
  user: User;
  gSTAmount: number;
  gstPercentage = 0.18;
  isCorporate = true;
  User: User;
  planGSTAmount: any;
  planTotalAmount: any;
  currentDate = new Date();
  certificateCreated = false;
  issueDateString: string;
  issueDate : Date;
  startDate : Date;
  endDate : Date;
  minDobDate : Date;
  maxDobDate : Date;
  ageOrDobValue = ["dob","age"];
  minDobDateModel =  {"year":2010, "month": 1,"day": 1};
  maxDobDateModel =  {"year":2010, "month": 1,"day": 1};
  newYears : Number[];
  showPaymentButton = false;
  showPaymentGatewayForm = false;
  renewCertificate = false;  
  oldCertificateValue: Certificate;
  insPolicyTotalAmount: number;
  insPolicyAmount: number;
  dealarConfigFound: boolean = false;
  dealerConfig: DealerModelConfig;
  selectDealerModelPrice: DealerModelPrice;
  allDealerConfig: DealerModelConfig[] =[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private alertService: AlertService,
    private configDataService: ConfigDataService,
    private dealerService: DealerService,
    private certificateService: CertificateService,
    private route: ActivatedRoute,
    private router: Router,

  ) {

  }

  ngOnInit(): void {
    this.renewCertificate = this.router.url.includes('renewCertificate');
    this.user = this.accountService.userValue;
    this.selectDealerModelPrice = new DealerModelPrice();
    this.selectDealerModelPrice.totalAmount = 0;
    this.selectDealerModelPrice.insuranceAmount = 0;
    this.selectDealerModelPrice.allianceAmount = 0;

    this.certficateForm = this.formBuilder.group({
      vehicleMake: ['', Validators.required],
      model: ['', Validators.required],
      type: ['', Validators.required],
      variant: [''],
      vehicleYear: [''],
      registrationNumber: [''],
      chasisNumber: ['', Validators.required],
      engineNumber: ['', Validators.required],
      clientType:['',Validators.required],
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: [''],
      vehicleType: ['Two Wheeler'],
      email: ['', [ Validators.email]],
      contactNumber: ['', [Validators.required, Validators.minLength(6)]],
      employeeContactNumber: ['', [Validators.required, Validators.minLength(6)]],
      gender: ['', Validators.required],
      dob: ['', Validators.required],
      ageOrDob: [this.ageOrDobValue[0]],
      gstin: [''],
      address: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      nomineeName: ['', Validators.required],
      nomineeRelationship: ['', Validators.required],
      nomineeGender: ['', Validators.required],
      nomineeAge: ['', [Validators.required, Validators.min(1),Validators.max(100)]],
      age: ['', [Validators.required, Validators.min(15),Validators.max(80)]],
      planId: ['', Validators.required],
      planAmount: ['']
      , planGSTAmount: ['']
      , planTotalAmount: ['', [Validators.required,
      (control: AbstractControl) => Validators.max(this.maxAmount)(control)
        , (control: AbstractControl) => Validators.min(this.minAmount)(control)]]
      , startDate: ['', Validators.required]
      , endDate: ['']
      , issueDate: ['']
      , balance: ['']
      , userId: ['']
      , certificateNumber: ['']
      , policyNumber: ['']
      , financeCompanyName: ['']
      , financeCoverAmount: [50000]
      ,insPolicyAmount:[{value:0,'disabled':true},[Validators.required,Validators.min(0),Validators.max(40000)]]
      ,insPolicyGStAmount:[{value:0,'disabled':true},Validators.required]
      ,insPolicyTotalAmount:[0,[Validators.required,Validators.min(0),Validators.max(40000)]]
      ,packageAmount:[{value:0,'disabled':true}]
      ,packageGstAmount:[{value:0,'disabled':true}]
      ,packageTotalAmount:[{value:0,'disabled':true}]
    });
    this.configDataService.FinanceCompanyList$.subscribe((x) => {
      this.financeCompanies = x;
    });
    this.startDateModel =  {"year":2010, "month": 1,"day": 1};
    this.startDateModel.year = this.currentDate.getFullYear();
    this.startDateModel.day = this.currentDate.getDate();
    this.startDateModel.month = this.currentDate.getMonth() + 1;



    this.configDataService.getTodaysDate()
    .subscribe(x => {
      this.currentDate = new Date(x);
      this.issueDate = new Date(x);
      this.startDate = new Date(x);
      this.endDate = new Date(new Date().setFullYear(this.currentDate.getFullYear() + 1));
      this.minDobDate = new Date(new Date().setFullYear(this.currentDate.getFullYear() - 80));
      this.maxDobDate = new Date(new Date().setFullYear(this.currentDate.getFullYear() - 15));
      this.newYears = [this.currentDate.getFullYear(), this.currentDate.getFullYear() - 1];

      this.startDateModel.year = this.currentDate.getFullYear();
      this.startDateModel.day = this.currentDate.getDate();
      this.startDateModel.month = this.currentDate.getMonth() + 1;
      this.certficateForm.controls['startDate'].setValue(this.startDateModel);
  
      this.minDobDateModel.year = this.minDobDate.getFullYear();
      this.minDobDateModel.day = this.minDobDate.getDate();
      this.minDobDateModel.month = this.minDobDate.getMonth() + 1;
  
      this.maxDobDateModel.year = this.maxDobDate.getFullYear();
      this.maxDobDateModel.day = this.maxDobDate.getDate();
      this.maxDobDateModel.month = this.maxDobDate.getMonth() + 1;
      if (this.user.specialRoleAccess && this.user.specialRoleAccess.includes('canSeeAge'))
      {
        this.certficateForm.controls['ageOrDob'].setValue(this.ageOrDobValue[1]);
        this.certficateForm.get('dob').clearValidators(); 
        this.certficateForm.get('dob').updateValueAndValidity();
      }  
        

    this.configDataService.StateCity$.subscribe((x) => {
      this.stateCity = x;
    });
    // // this.configDataService.getMakeModel().subscribe((x)=>{
    // //   this.configMakeModel = x;
    // // }
    // )

    this.certficateForm.controls['planAmount'].disable();
    this.certficateForm.controls['planGSTAmount'].disable();
    this.certficateForm.controls['planTotalAmount'].disable();
    this.certficateForm.controls['endDate'].disable();
    this.certficateForm.controls['issueDate'].disable();
    this.certficateForm.controls['balance'].disable();

    // if ((this.user.role == "executive") || (this.user.role =="manager") || (this.user.role == "dealerOwner")) {
      this.dealerService.getByDealerId(this.user.dealerId).subscribe((x) => {
        this.dealer = x;
        this.plans = x.dealerPlan;
        this.certficateForm.controls['balance'].setValue(this.dealer.dealerBalance);
        // this.twoWheelerPlans = x.dealerPlan.filter(x => !x.policyType.includes('(CAR)'));
        // this.nonTwoWheelerPlans = x.dealerPlan.filter(x => x.policyType.includes('(CAR)'));
        this.showPaymentButton = this.dealer.dealerPaymentShow ? this.dealer.dealerPaymentShow == "Yes" ? true : false : false;
        this.makeModel = x.dealerMake;
        if (!this.dealer.dealerCreatePackage)
        {
          this.certficateForm.controls['insPolicyAmount'].clearValidators();
          this.certficateForm.controls['insPolicyGStAmount'].clearValidators();
          this.certficateForm.controls['insPolicyTotalAmount'].clearValidators();
          this.certficateForm.controls['insPolicyAmount'].updateValueAndValidity();
          this.certficateForm.controls['insPolicyGStAmount'].updateValueAndValidity();
          this.certficateForm.controls['insPolicyTotalAmount'].updateValueAndValidity();
        }
        this.configDataService.getDealerModelConfig(this.user.dealerId).pipe(first()).subscribe({
          next: (x) => {
            this.allDealerConfig = x;
            this.dealarConfigFound = false;
            this.certficateForm.controls['type'].setValue(this.types[0]);
            // this.dealerConfig = this.allDealerConfig.find(a=>[undefined,"Both","New"].includes(a.applicableOn));
            // if (this.allDealerConfig && this.allDealerConfig.length > 0){
            //   this.dealarConfigFound = true;
            // }
            // else 
            // {
            //   this.setDefaultMakeModel();
            // }
        },
        error: error => {
          // this.alertService.error(error);
          this.allDealerConfig = [];
          this.certficateForm.controls['type'].setValue(this.types[0]);
          // this.setDefaultMakeModel();
        }});
          }
      )

    this.certficateForm.controls['userId'].setValue(this.user.id);
    this.certficateForm.get('planAmount').disable;
    }
    )
    this.selectedPlan.numberOfYears = this.selectedPlan.numberOfYears? this.selectedPlan.numberOfYears : 1;

    if (this.renewCertificate)
    {
      this.renewCertificateUpdate();
    }
  this.maintainClientType();
    this.maintainModelandMakeValueChanges();
    this.maintainMiscValueChanges();
      this.maintainPlanValueChanges();
      this.maintainDateTimeValueChanges();
  
  }
  getMakeModelFromDealerConfig() {
    this.makeModel = [...new Set(this.dealerConfig.dealerModelPrices.map(a => a.make))].map(a=> {
      return {
        make: a
      , model: [...new Set(this.dealerConfig.dealerModelPrices.filter(b => b.make == a).map(r=> r.model))]
      , productType: "Two Wheeler"
    }});
    if (!this.renewCertificate) {
      this.selectedMakeModel = this.makeModel[0];
      this.makeModelUpdatenonRenew();
    }
  else {
    this.selectedMakeModel = this.makeModel.find(x => x.make == this.oldCertificateValue.vehicleMake);        
    this.renewCertificateUpdateAfter();
  }        

}


  get f() { return this.certficateForm.controls; }
  setDefaultMakeModel(){
    this.configDataService.MakeModel$.subscribe(x => {
      if (!this.renewCertificate) {
        this.selectedMakeModel = x.find(x => x.make == this.makeModel[0].make);
        this.makeModelUpdatenonRenew();
      }
    else {
      this.makeModel = x;
      this.selectedMakeModel = x.find(x => x.make == this.oldCertificateValue.vehicleMake);
      this.renewCertificateUpdateAfter();
    }
    })
  }
  makeModelUpdatenonRenew(){

    this.certficateForm.controls['vehicleMake'].setValue(this.makeModel[0].make);
  this.certficateForm.controls['gender'].setValue(this.genders[0]);
  this.certficateForm.controls['nomineeGender'].setValue(this.genders[1]);
  this.certficateForm.controls['nomineeRelationship'].setValue(this.relationships[0]);
  this.certficateForm.controls['state'].setValue(this.dealer.dealerState);
  this.certficateForm.controls['clientType'].setValue(this.clientTypes[0]);
}
  renewCertificateUpdate(){
    this.oldCertificateValue = this.certificateService.PrintCertificate;
    this.certficateForm.controls['variant'].setValue(this.oldCertificateValue.variant);
    this.certficateForm.controls['registrationNumber'].setValue(this.oldCertificateValue.registrationNumber);
    this.certficateForm.controls['clientType'].setValue(this.oldCertificateValue.clientType?this.oldCertificateValue.clientType:this.clientTypes[0]);
    this.certficateForm.controls['engineNumber'].setValue(this.oldCertificateValue.engineNumber);
    this.certficateForm.controls['chasisNumber'].setValue(this.oldCertificateValue.chasisNumber);
    this.certficateForm.controls['firstName'].setValue(this.oldCertificateValue.firstName?this.oldCertificateValue.firstName:"");
    this.certficateForm.controls['lastName'].setValue(this.oldCertificateValue.lastName?this.oldCertificateValue.lastName:"");
    this.certficateForm.controls['middleName'].setValue("");
    this.certficateForm.controls['gstin'].setValue("");
    this.certficateForm.controls['vehicleType'].setValue("Two Wheeler");
    this.certficateForm.controls['email'].setValue(this.oldCertificateValue.email?this.oldCertificateValue.email:"");
    this.certficateForm.controls['contactNumber'].setValue(this.oldCertificateValue.contactNumber?this.oldCertificateValue.contactNumber:"");
    this.certficateForm.controls['employeeContactNumber'].setValue(this.oldCertificateValue.employeeContactNumber?this.oldCertificateValue.employeeContactNumber:"");
    this.certficateForm.controls['gender'].setValue(this.oldCertificateValue.gender?this.oldCertificateValue.gender:"");
    this.certficateForm.controls['address'].setValue(this.oldCertificateValue.address?this.oldCertificateValue.address:"");
    this.certficateForm.controls['state'].setValue(this.oldCertificateValue.state?this.oldCertificateValue.state:"");
    this.certficateForm.controls['city'].setValue(this.oldCertificateValue.city?this.oldCertificateValue.city:"");
    this.certficateForm.controls['nomineeName'].setValue(this.oldCertificateValue.nomineeName?this.oldCertificateValue.nomineeName:"");
    this.certficateForm.controls['nomineeRelationship'].setValue(this.oldCertificateValue.nomineeRelationship?this.oldCertificateValue.nomineeRelationship:"");
    this.certficateForm.controls['nomineeGender'].setValue(this.oldCertificateValue.nomineeGender?this.oldCertificateValue.nomineeGender:"");
    this.certficateForm.controls['nomineeAge'].setValue(this.oldCertificateValue.nomineeAge?this.oldCertificateValue.nomineeAge:"");
    this.certficateForm.controls['financeCompanyName'].setValue(this.oldCertificateValue.financeCompanyName?this.oldCertificateValue.financeCompanyName:"");
    this.certficateForm.controls['financeCoverAmount'].setValue(this.oldCertificateValue.financeCoverAmount?this.oldCertificateValue.financeCoverAmount:50000);
  }
  renewCertificateUpdateAfter(){
    this.certficateForm.controls['type'].setValue(this.types[1]); 
    if (this.oldCertificateValue.ageOrDob && this.oldCertificateValue.ageOrDob == this.ageOrDobValue[1])
    {
      this.certficateForm.controls['age'].setValue(this.oldCertificateValue.age);
      this.certficateForm.controls['dob'].setValue("NA");
    }
    else 
    {
    if (this.oldCertificateValue.dob != "NA")
    {
    this.startDateModel.year = parseInt(this.oldCertificateValue.dob.split('-')[0]);
    this.startDateModel.day = parseInt(this.oldCertificateValue.dob.split('-')[2]);
    this.startDateModel.month = parseInt(this.oldCertificateValue.dob.split('-')[1]);
    this.certficateForm.controls['dob'].setValue(this.startDateModel);
    }
    else
    {
      this.certficateForm.controls['dob'].setValue("NA");
    }
  }
    this.certficateForm.controls['clientType'].setValue(this.oldCertificateValue.clientType?this.oldCertificateValue.clientType:this.clientTypes[0]);
    this.certficateForm.controls['vehicleMake'].setValue(this.oldCertificateValue.vehicleMake);    
    this.certficateForm.controls['model'].setValue(this.oldCertificateValue.model);    
    this.certficateForm.controls['vehicleType'].setValue("Two Wheeler");
    this.certficateForm.controls['vehicleYear'].setValue(this.oldCertificateValue.vehicleYear);
    this.certficateForm.controls['state'].setValue(this.oldCertificateValue.state?this.oldCertificateValue.state:"");
    this.certficateForm.controls['city'].setValue(this.oldCertificateValue.city?this.oldCertificateValue.city:"");
    this.certficateForm.controls['nomineeName'].setValue(this.oldCertificateValue.nomineeName?this.oldCertificateValue.nomineeName:"");
    this.certficateForm.controls['nomineeRelationship'].setValue(this.oldCertificateValue.nomineeRelationship?this.oldCertificateValue.nomineeRelationship:"");
    this.certficateForm.controls['nomineeGender'].setValue(this.oldCertificateValue.nomineeGender?this.oldCertificateValue.nomineeGender:"");
    this.certficateForm.controls['nomineeAge'].setValue(this.oldCertificateValue.nomineeAge?this.oldCertificateValue.nomineeAge:"");
    this.certficateForm.controls['financeCompanyName'].setValue(this.oldCertificateValue.financeCompanyName?this.oldCertificateValue.financeCompanyName:"");
    this.certficateForm.controls['financeCoverAmount'].setValue(this.oldCertificateValue.financeCoverAmount?this.oldCertificateValue.financeCoverAmount:50000);
  }
  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();
    // stop here if form is invalid
    if (this.certficateForm.invalid) {
      return;
    }

    if (this.plans.length == 0) {
      this.alertService.error("No active corporate plans activated for corporate clients. Please request support@allianceassure to activate corporate plans for corporate clients")
      return;
    }
    this.loading = true;
    var issueCertificate: Certificate;
    issueCertificate = new Certificate(this.certficateForm.value);
    if (!issueCertificate.email)
        issueCertificate.email = " ";
    
    if (this.isCorporate)
    { 
      issueCertificate.gender = "Organisation"
      issueCertificate.dob = "NA"
      issueCertificate.nomineeRelationship = "NA"   
    }
    else{
      issueCertificate.employeeContactNumber = "NA";
      this.customerDob = this.customerDob = this.convertTextDate(this.certficateForm.get('dob').value);
      issueCertificate.dob = this.customerDob;
      }
    if (this.dealer.dealerCreatePackage)
    {
      issueCertificate.showSummaryPage =  true;
    }
    else
    {
      issueCertificate.showSummaryPage =  false;
    }
    if (this.dealer.onePager)
    {
      issueCertificate.onePager =  true;
    }
    else
    {
      issueCertificate.onePager =  false;
    }

    issueCertificate.issueDateString = this.issueDate.getFullYear() + "-" + ('0' + (this.issueDate.getMonth() + 1)).slice(-2) + "-" + ('0' + this.issueDate.getDate()).slice(-2);
    issueCertificate.planAmount = this.certficateForm.get('planAmount').value;
    issueCertificate.insPolicyAmount = this.certficateForm.get('insPolicyAmount').value;    
    issueCertificate.insPolicyGStAmount = this.certficateForm.get('insPolicyGStAmount').value;

    issueCertificate.planGSTAmount = this.certficateForm.get('planGSTAmount').value;
    issueCertificate.planTotalAmount = this.certficateForm.get('planTotalAmount').value;
    issueCertificate.startDate = this.certficateForm.get('issueDate').value;
    issueCertificate.endDate = this.certficateForm.get('endDate').value;
    issueCertificate.issueDate = this.certficateForm.get('issueDate').value;
    issueCertificate.printCpaPolicy = this.selectedPlan.printCpaPolicy?this.selectedPlan.printCpaPolicy:"Yes";
    issueCertificate.financeCompanyName =  this.certficateForm.get('financeCompanyName').value;
    issueCertificate.financeCoverAmount =  this.certficateForm.get('financeCoverAmount').value;

    issueCertificate.planCPACoverAmount = this.selectedPlan.planCPACoverAmount?this.selectedPlan.planCPACoverAmount : 1500000;
    issueCertificate.printRsaNumber = this.selectedPlan.printRsaNumber? this.selectedPlan.printRsaNumber: "No";
    if (this.selectedPlan.policyType == "FINANCE")
    {
    issueCertificate.nomineeCoverAmount = issueCertificate.planCPACoverAmount - issueCertificate.financeCoverAmount;
  }
  else
  {
    issueCertificate.nomineeCoverAmount = issueCertificate.planCPACoverAmount ;

  }
  if (!(this.selectedPlan.printCpaPolicy && this.selectedPlan.printCpaPolicy == "No") && (issueCertificate.nomineeName == "NA"))
  {
    this.alertService.error("Nominee Details invalid");
    this.loading = false;
    return;
  } 
    if (issueCertificate.registrationNumber.trim().length == 0) {
      issueCertificate.registrationNumber = "New";
      // console.log("I am here: " + issueCertificate.registrationNumber);
    }
    // else
    //    console.log("registrationNumber: " + issueCertificate.registrationNumber);
    // console.log(JSON.stringify(issueCertificate));
    this.certificateService.issueCertificate(issueCertificate)
      .pipe(first())
      .subscribe({
        next: (x) => {
          this.certificateResponse = new CertificateResponse(x);
          // console.log("CertificateResponse:" + JSON.stringify(this.certificateResponse));
          this.certficateForm.controls['certificateNumber'].setValue(this.certificateResponse.certificate.certificateNumber);
          this.certficateForm.controls['policyNumber'].setValue(this.certificateResponse.certificate.policyNumber);
          this.certficateForm.controls['balance'].setValue(this.certificateResponse.dealer.dealerBalance);
          this.dealer.dealerBalance = this.certificateResponse.dealer.dealerBalance;
          this.certificateCreated = true;

          this.alertService.success('Certificate added successfully', { keepAfterRouteChange: true });
          this.loading = false;
          this.onPdfClick();
        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });

    return;
  }
  paymentGatewayShowToggle(){
    this.showPaymentGatewayForm = !this.showPaymentGatewayForm;
    console.log("i am here");
  }
  convertTextDate(value: NgbDateStruct) {
    if (value) {
      this.dobString = value.year.toString();
      if (value.month < 10) {
        this.dobString = this.dobString + "-0" + value.month;
      }
      else {
        this.dobString = this.dobString + "-" + value.month;
      }
      if (value.day < 10) {
        this.dobString = this.dobString + "-0" + value.day;
      }
      else {
        this.dobString = this.dobString + "-" + value.day;
      }
    }
    return this.dobString;
  }
  balanceValidator() {
    if (this.certficateForm.get('planTotalAmount').value != undefined) {
      this.runningBalance = this.dealer.dealerBalance - parseInt(this.certficateForm.get('planTotalAmount').value)
      // console.log("Dealer Balance" + this.runningBalance);

      if (this.runningBalance < this.dealer.dealerBalanceLimit) {
        this.isBalanceLow = true;
      }
      else
        this.isBalanceLow = false;
    }
  }
  onReset() {
    this.certificateCreated = false;
    this.submitted = false;
    this.alertService.clear();
    window.location.reload();
  }
  financeSelected(inputFinance:string)
  {
    this.certficateForm.controls["financeCompanyName"].setValue(inputFinance);
    this.selectedFinance = inputFinance;
  }

  onPdfClick() {
    this.certificateService.PrintCertificate = this.certificateResponse.certificate;
    this.alertService.clear();
    switch (true)
    {
      case this.certificateResponse.certificate.policyType.startsWith("RSA (CAR)") :
        this.router.navigate(['../print/RSAPv'], { relativeTo: null });
        break;
      case this.certificateResponse.certificate.policyType.startsWith("CPA + RSA + DOC") :
        this.router.navigate(['../print/RSANewDoc'], { relativeTo: null });
        break;
      case this.certificateResponse.certificate.policyType.toUpperCase().startsWith("RSA EV") :
        this.router.navigate(['../print/RSAEv'], { relativeTo: null });
        break;
      case this.certificateResponse.certificate.policyType.includes("RSA"):
        this.router.navigate(['../print/RSANew'], { relativeTo: null });
        break;
      case this.certificateResponse.certificate.policyType.includes("CPA + DOC") || (this.certificateResponse.certificate.gstShow && this.certificateResponse.certificate.gstShow == "N" && this.certificateResponse.certificate.policyType.includes("PA")):
        this.router.navigate(['../print/CpaDoc'], { relativeTo: null });
        break;

      case this.certificateResponse.certificate.policyType.includes("FINANCE"):
        this.router.navigate(['../print/Finance'], { relativeTo: null });
        break;
      default:
        this.router.navigate(['../print/PaNew'], { relativeTo: null });
        break;

    }
  }
  maintainClientType() {
    this.certficateForm.get('clientType').valueChanges
    .subscribe(value => {
      if (value == "Individual")
      {
        this.isCorporate = false;
        this.certficateForm.controls['nomineeGender'].setValue(this.genders[1]);
        this.certficateForm.get('gender').setValidators(Validators.required);
        this.certficateForm.get('dob').setValidators(Validators.required);
        this.certficateForm.get('nomineeRelationship').setValidators(Validators.required);
        this.certficateForm.get('employeeContactNumber').clearValidators();        
        // if (this.selectedMakeModel.productType.includes('Two'))
        // {
        //   this.plans = this.twoWheelerPlans;
        // }
        // else {
        //   this.plans = this.nonTwoWheelerPlans;

        // }

      }
      else
      {
        this.isCorporate = true;
        this.certficateForm.controls['nomineeGender'].setValue(this.genders[0]);
        this.certficateForm.get('gender').clearValidators();
        this.certficateForm.get('dob').clearValidators();
        this.certficateForm.get('age').clearValidators();
        this.certficateForm.get('nomineeRelationship').clearValidators();
        this.certficateForm.get('employeeContactNumber').setValidators([Validators.required, Validators.minLength(6)]);
        // if (this.selectedMakeModel.productType.includes('Two'))
        // {
        //   this.plans = this.twoWheelerPlans.filter(x => x.policyType.includes("RSA") || x.policyType.includes("DOC"));
        // }
        // else
        // {
        //   this.plans = this.nonTwoWheelerPlans.filter(x => x.policyType.includes("RSA") || x.policyType.includes("DOC"));

        // }

      }
      this.certficateForm.controls['gender'].updateValueAndValidity();
      this.certficateForm.controls['dob'].updateValueAndValidity();
      this.certficateForm.controls['age'].updateValueAndValidity();
      this.certficateForm.controls['nomineeRelationship'].updateValueAndValidity();
      this.certficateForm.controls['employeeContactNumber'].updateValueAndValidity();
      this.filterPlanList();
      // if (this.plans.length > 0 && !this.renewCertificate)
      //   this.certficateForm.controls['planId'].setValue(this.plans[0].planId);
    });
  }

  maintainPlanValueChanges(){
    this.certficateForm.get('planId').valueChanges
      .subscribe(value => {
        if (value != undefined) {
          this.selectedPlan = this.plans.filter((x) => x.planId == value)[0];
          this.selectedPlan.numberOfYears = this.selectedPlan.numberOfYears? this.selectedPlan.numberOfYears : 1;
          //console.log(this.selectedPlan);
          if (!this.dealarConfigFound)
          {
          this.certficateForm.controls['planAmount'].setValue(this.selectedPlan.planAmount);
          this.certficateForm.controls['planGSTAmount'].setValue(this.selectedPlan.planGstAmount);
          this.certficateForm.controls['planTotalAmount'].setValue(this.selectedPlan.planAmount + this.selectedPlan.planGstAmount);
          }
          else
          {
            this.certficateForm.controls['planTotalAmount'].setValue(this.selectDealerModelPrice.totalAmount - this.selectDealerModelPrice.insuranceAmount - this.f.insPolicyTotalAmount.value);
          }
          this.maxAmount = this.selectedPlan.planMaximumAmount;
          this.minAmount = this.selectedPlan.planMinimumAmount? this.selectedPlan.planMinimumAmount : this.selectedPlan.planNetAmount;
          this.certficateForm.controls['planAmount'].updateValueAndValidity();
          this.certficateForm.controls['startDate'].setValue(this.startDateModel);
          this.endDate = new Date(this.startDate);
          this.endDate = new Date(this.endDate.setFullYear(this.endDate.getFullYear() + ((this.selectedPlan.numberOfYears && this.selectedPlan.numberOfYears > 0)?this.selectedPlan.numberOfYears:1)));
          this.endDate = new Date(this.endDate.setDate(this.endDate.getDate() - 1));
          this.endDate = new Date(this.endDate.setHours(0, 0, 0, 0));

          this.certficateForm.controls['endDate'].setValue(this.endDate);
          this.certficateForm.controls['issueDate'].setValue(this.currentDate);
          this.showGstAmount = this.selectedPlan.gstApplicable ? this.selectedPlan.gstApplicable =="N" ? false : true : true; 
          this.showGstAmount = this.showGstAmount == false? false : this.selectedPlan.gstShow ? this.selectedPlan.gstShow =="N" ? false : true : true; 
          if (this.selectedPlan.policyType == "FINANCE")
          {

            this.showFinance = true;
            this.certficateForm.get('financeCompanyName').setValidators(Validators.required);
            this.certficateForm.get('financeCoverAmount').setValidators(Validators.required);
            this.selectedPlan.planCPACoverAmount = this.selectedPlan.planCPACoverAmount?this.selectedPlan.planCPACoverAmount:500000;
            this.certficateForm.get('financeCoverAmount').setValidators(Validators.max(this.selectedPlan.planCPACoverAmount));
  
          }
          else {
            this.showFinance = false;
            this.certficateForm.get('financeCompanyName').clearValidators();
            this.certficateForm.get('financeCoverAmount').clearValidators();
          }
          this.certficateForm.controls['financeCompanyName'].updateValueAndValidity();
          this.certficateForm.controls['financeCoverAmount'].updateValueAndValidity();
          this.maintainNomineeValidators();
    
        }

          if (this.selectedPlan.planAmount == 0) {
            this.certficateForm.controls['planTotalAmount'].enable();
        }
        else {
          this.certficateForm.controls['planTotalAmount'].disable();
        }
      });

    this.certficateForm.get('planTotalAmount').valueChanges
      .subscribe(value => {
        if (value != undefined) {
            if (this.selectedPlan.planAmount == 0) {
              this.planTotalAmount = parseInt(value);
            this.planAmount = (this.planTotalAmount / (1 + this.gstPercentage)).toFixed(2);
            this.planGSTAmount = (this.planTotalAmount - this.planAmount).toFixed(2);
            this.minAmount = this.selectedPlan.planMinimumAmount? this.selectedPlan.planMinimumAmount :1000;
            this.certficateForm.controls['planGSTAmount'].setValue(this.planGSTAmount);
            this.certficateForm.controls['planAmount'].setValue(this.planAmount);
            this.balanceValidator();
          }
        }
      });

  }
  maintainNomineeValidators() {
    // if (!this.isCorporate)
    // {
    if (this.selectedPlan.printCpaPolicy && this.selectedPlan.printCpaPolicy=="No") {
        this.certficateForm.controls['nomineeGender'].setValue("NA");
          this.certficateForm.controls['nomineeRelationship'].setValue("NA");
          this.certficateForm.controls['nomineeName'].setValue("NA");
          this.certficateForm.controls['nomineeAge'].setValue(99);
          this.certficateForm.get('employeeContactNumber').clearValidators();
        }
        else
        {
          this.certficateForm.controls['nomineeGender'].setValue(this.genders[1]);
          this.certficateForm.controls['nomineeRelationship'].setValue(this.relationships[0]);
          this.certficateForm.controls['nomineeName'].setValue("");
          this.certficateForm.controls['nomineeAge'].reset();
          if (this.isCorporate){
          this.certficateForm.get('employeeContactNumber').setValidators([Validators.required, Validators.minLength(6)]);
          }
          else
         {
            this.certficateForm.get('employeeContactNumber').clearValidators();
          }

        }
        this.certficateForm.controls['employeeContactNumber'].updateValueAndValidity();

    // }
  
  }
  maintainDateTimeValueChanges() {
    this.certficateForm.get('startDate').valueChanges
    .subscribe(value =>{
      if (value != undefined){
        if(value.month != undefined && value.day != undefined && value.year != undefined){
            this.startDate = new Date(this.convertTextDate(value));
            if (parseInt(value.month) == (this.currentDate.getMonth() + 1) && parseInt(value.day) == this.currentDate.getDate() && parseInt(value.year) == this.currentDate.getFullYear())
            this.startDate = new Date(this.currentDate);
            else
            this.startDate = new Date(this.startDate.setHours(0, 0, 0, 0));


            this.endDate = new Date(this.startDate);
            this.endDate = new Date(this.endDate.setFullYear(this.endDate.getFullYear() + ((this.selectedPlan.numberOfYears && this.selectedPlan.numberOfYears > 0)?this.selectedPlan.numberOfYears:1)));
            this.endDate = new Date(this.endDate.setDate(this.endDate.getDate() - 1));
            this.endDate = new Date(this.endDate.setHours(0, 0, 0, 0));
            this.certficateForm.controls['endDate'].setValue(this.endDate);
            this.certficateForm.controls['issueDate'].setValue(this.startDate);
            this.issueDate = this.startDate;
                      }
      }

    });
    this.certficateForm.get('dob').valueChanges
    .subscribe(value => {
    if (value && value.year && value.year != 1001)
    {
      this.certficateForm.controls['age'].setValue(this.currentDate.getFullYear() - parseInt(value.year));
    }
    });
    this.certficateForm.get('type').valueChanges
    .subscribe(value => {
      this.dealerConfig = this.allDealerConfig.find(a=>[undefined,"Both",value].includes(a.applicableOn));
      if (this.dealerConfig && this.dealerConfig.dealerModelPrices.length > 0) {
        this.dealarConfigFound = true;
        this.getMakeModelFromDealerConfig();
      } else {
        this.dealarConfigFound = false;
        this.setDefaultMakeModel();
      }

      if (value == "Old") {
        this.certficateForm.controls['vehicleYear'].setValue(this.currentDate.getFullYear());
        this.certficateForm.get('registrationNumber').setValidators(Validators.required);
        this.years = [this.currentDate.getFullYear(), this.currentDate.getFullYear() - 1
          , this.currentDate.getFullYear() - 2
          , this.currentDate.getFullYear() - 3
          , this.currentDate.getFullYear() - 4
          , this.currentDate.getFullYear() - 5
          , this.currentDate.getFullYear() - 6
          , this.currentDate.getFullYear() - 7
          , this.currentDate.getFullYear() - 8
          , this.currentDate.getFullYear() - 9
          , this.currentDate.getFullYear() - 10
          , this.currentDate.getFullYear() - 11
          , this.currentDate.getFullYear() - 12
          , this.currentDate.getFullYear() - 13
          , this.currentDate.getFullYear() - 14
        ];
        this.certficateForm.controls['startDate'].enable();
      }

      else {
        this.years = [this.currentDate.getFullYear(), this.currentDate.getFullYear() - 1, this.currentDate.getFullYear() - 2];
        this.certficateForm.get('registrationNumber').clearValidators();
        this.certficateForm.controls['startDate'].disable();
      }
      this.filterPlanList();
      this.certficateForm.controls['registrationNumber'].updateValueAndValidity();
      this.certficateForm.controls['startDate'].updateValueAndValidity();
      if (!this.renewCertificate) this.certficateForm.controls['vehicleYear'].setValue(this.years[0]);
    });
}

maintainModelandMakeValueChanges(){
  this.certficateForm.get('model').valueChanges
  .subscribe(value => {
    if (value == "Others") {
      this.certficateForm.get('variant').setValidators(Validators.required);
    }
    else {
      this.certficateForm.get('variant').clearValidators();
    }
    this.certficateForm.controls['variant'].updateValueAndValidity();
    if (this.dealarConfigFound){
      this.selectDealerModelPrice = this.dealerConfig.dealerModelPrices.find(a => a.model == value);
      this.f.variant.setValue(this.selectDealerModelPrice.heroVariant);
      this.selectDealerModelPrice.insuranceAmount = this.selectDealerModelPrice.insuranceAmount ?this.selectDealerModelPrice.insuranceAmount:0;
      this.f.insPolicyTotalAmount.setValue(this.selectDealerModelPrice.insuranceAmount);
      this.f.planTotalAmount.setValue(this.selectDealerModelPrice.allianceAmount);
      this.f.planId.setValue(this.plans.find(a => a.planAmount == 0).planId);
    }
  });
  this.certficateForm.get('vehicleMake').valueChanges.subscribe((value) => {
    if (value != undefined) {
      if (this.dealarConfigFound)
        {
          this.selectedMakeModel = this.makeModel.find(a => a.make == value);
          this.certficateForm.controls['vehicleType'].setValue(this.selectedMakeModel.productType);
        this.models =  this.selectedMakeModel.model;
        this.filterPlanList();
        }
        else {
      this.configDataService.MakeModel$.subscribe(
        (x) => {this.selectedMakeModel = x.find(a => a.make == value);
          this.certficateForm.controls['vehicleType'].setValue(this.selectedMakeModel.productType);
        this.models =  this.selectedMakeModel.model;
        // if (this.selectedMakeModel.productType.includes("Two"))
        // {
        //  this.plans = this.twoWheelerPlans;   
        // }
        // else
        // {
        //   this.plans = this.nonTwoWheelerPlans;   

        // }
        // if (this.plans.length > 0 && !this.renewCertificate)
        // this.certficateForm.controls['planId'].setValue(this.plans[0].planId);
        this.filterPlanList();

        }
        
      )
    }
    }
  });
  this.certficateForm.get('insPolicyTotalAmount').valueChanges
  .subscribe(value =>{
    if (value){
      this.insPolicyTotalAmount = parseInt(value);
      this.insPolicyAmount = parseFloat((this.insPolicyTotalAmount / (1 + this.gstPercentage)).toFixed(2));
      this.certficateForm.controls['insPolicyGStAmount'].setValue(parseFloat((this.insPolicyTotalAmount - this.insPolicyAmount).toFixed(2)))
      this.certficateForm.controls['insPolicyAmount'].setValue(this.insPolicyAmount)
      if(this.dealarConfigFound)
      {
      this.certficateForm.controls['planTotalAmount'].setValue(this.selectDealerModelPrice.totalAmount - this.insPolicyTotalAmount);
    }
    }
  });
  this.certficateForm.get('insPolicyAmount').valueChanges
  .subscribe(value =>{
    if (value){
      this.certficateForm.controls['packageAmount'].setValue(parseFloat(value) + parseFloat(this.certficateForm.get('planAmount').value))
    }
  });
  this.certficateForm.get('insPolicyGStAmount').valueChanges
  .subscribe(value =>{
    if (value){
      this.certficateForm.controls['packageGstAmount'].setValue(parseFloat(value) + parseFloat(this.certficateForm.get('planGSTAmount').value))
    }
  });
  this.certficateForm.get('planAmount').valueChanges
  .subscribe(value =>{
    if (value){
      this.certficateForm.controls['packageAmount'].setValue(parseFloat((parseFloat(value) + parseFloat(this.certficateForm.get('insPolicyAmount').value)).toFixed(2)))
    }
  });
  this.certficateForm.get('planGSTAmount').valueChanges
  .subscribe(value =>{
    if (value){
      this.certficateForm.controls['packageGstAmount'].setValue(parseFloat((parseFloat(value) + parseFloat(this.certficateForm.get('insPolicyGStAmount').value)).toFixed(2)))
    }
  });
  this.certficateForm.get('packageAmount').valueChanges
  .subscribe(value =>{
    if (value){
      this.certficateForm.controls['packageTotalAmount'].setValue(parseFloat(parseInt(value + this.certficateForm.get('packageGstAmount').value).toFixed(2)))
    }
  });
  this.certficateForm.get('packageGstAmount').valueChanges
  .subscribe(value =>{
    if (value){
      this.certficateForm.controls['packageTotalAmount'].setValue(parseFloat(parseInt(value + this.certficateForm.get('packageAmount').value).toFixed(2)))
    }
  });

}
maintainMiscValueChanges(){
  this.certficateForm.get('financeCompanyName').valueChanges.subscribe((value) => {
    if (value){
      this.nameRegexp = new RegExp(value,'i'); 
      this.selectedFinanceCompanies = this.financeCompanies.filter(a => {
      return this.nameRegexp.test(a);
    })}
    else
    this.selectedFinanceCompanies = [];
 });

 this.certficateForm.get('ageOrDob').valueChanges.subscribe((value) => {
  if (value != undefined) {
    if (value == this.ageOrDobValue[1])
    {
      // this.certficateForm.get('age').setValidators([Validators.required,Validators.min(18),Validators.max(80)]);
      this.certficateForm.get('age').setValue(24);
      // this.certficateForm.get('age').setValidators(Validators.max(80));
      this.certficateForm.get('dob').clearValidators(); 
      this.minDobDateModel.year  = 1001;         
      this.minDobDateModel.month  = 1;         
      this.minDobDateModel.day  = 1;         
      this.certficateForm.controls['dob'].setValue(this.minDobDateModel);

    }
    else
    {
      {
        this.maxDobDateModel.year = this.maxDobDate.getFullYear();
        this.maxDobDateModel.day = this.maxDobDate.getDate();
        this.maxDobDateModel.month = this.maxDobDate.getMonth() + 1;
            this.certficateForm.controls['dob'].setValue(this.maxDobDateModel);
            this.minDobDateModel.year = this.minDobDate.getFullYear();
            this.minDobDateModel.day = this.minDobDate.getDate();
            this.minDobDateModel.month = this.minDobDate.getMonth() + 1;
                this.certficateForm.controls['dob'].setValue(this.minDobDateModel);
            this.certficateForm.get('dob').setValidators(Validators.required);
        this.certficateForm.get('age').clearValidators(); 
      }  
    }
    this.certficateForm.get('age').updateValueAndValidity(); 
    this.certficateForm.get('dob').updateValueAndValidity(); 
  }
});

this.certficateForm.get('state').valueChanges.subscribe((value) => {
  if (value != undefined) {
    this.configDataService.StateCity$.subscribe(
      (x) => this.cities = x.find(a => a.state == value).city
    )
  }
})
}
filterPlanList(){
  if (!this.plans) return;

  var filteredPlan = this.dealer.dealerPlan;
  if (this.selectedMakeModel)
  {
        if (this.selectedMakeModel.productType.includes("Two"))
        {
          filteredPlan = this.dealer.dealerPlan.filter(x => !x.policyType.includes('(CAR)'));;   
        }
        else
        {
          filteredPlan = this.dealer.dealerPlan.filter(x => x.policyType.includes('(CAR)'));;   
  
        }
  }
  if (this.certficateForm.get('clientType').value == this.clientTypes[1])
  {
    filteredPlan = filteredPlan.filter(x => x.policyType.includes("RSA") || x.policyType.includes("DOC"));
  }
  if (this.certficateForm.get('type').value == this.types[0])
  {
    filteredPlan = filteredPlan.filter(x => !(x.applicableOnlyOnPolicyType && x.applicableOnlyOnPolicyType == this.types[1]));
  }
  if (this.certficateForm.get('type').value == this.types[1])
  {
    filteredPlan = filteredPlan.filter(x => !(x.applicableOnlyOnPolicyType && x.applicableOnlyOnPolicyType == this.types[0]));
  }
  if (this.dealarConfigFound)
  {
    filteredPlan = filteredPlan.filter(x => x.planAmount == 0);
  }
  this.plans = filteredPlan;
  if (this.plans.length > 0 && !this.renewCertificate) {
  this.certficateForm.controls['planId'].setValue(this.plans[0].planId);
};


}

}
