import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from '@app/_models';
import { Certificate } from '@app/_models/certificate';
import { CertificateResponse } from '@app/_models/certificateResponse';
import { Dealer } from '@app/_models/dealer';
import { MakeModel } from '@app/_models/makeModel';
import { PlanDetails } from '@app/_models/planDetails';
import { StateCity } from '@app/_models/stateCity';
import { AccountService } from '@app/_services/account.service';
import { AlertService } from '@app/_services/alert.service';
import { CertificateService } from '@app/_services/certificate.service';
import { ConfigDataService } from '@app/_services/config-data.service';
import { DealerService } from '@app/_services/dealer-service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { first } from 'rxjs/internal/operators/first';
import { ExcelBulkIndvTemplateService } from './excel-bulk-indv-template.service';

@Component({
  selector: 'app-bulk-individual',
  templateUrl: './bulk-individual.component.html',
  styleUrls: ['./bulk-individual.component.less']
})
export class BulkIndividualComponent implements OnInit {
  user:User;
  maxAmount = 99999;
  minAmount = 0;
  showHondaExcel = false;
  plans: PlanDetails[];
  certificates : Certificate[];
  gstPercentage = 0.18;
  showFinance = false;
  startDateModel: NgbDateStruct;
  showGstAmount = true;
  loading = false;
  isBalanceLow = false;
  selectedFinanceCompanies: string[];
  dealer:Dealer;
  submitted = false;
  certficateForm: UntypedFormGroup;
  selectedPlan =  new PlanDetails();
  selectedFinance:string;
  twoWheelerModels: MakeModel[];
  nonTwoWheelerModels: MakeModel[];
  financeCompanies: string[];
  endDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  currentDate = new Date();
  nameRegexp: RegExp;
  planTotalAmount: number;
  planAmount: any;
  planGSTAmount: any;
  runningBalance: number;
  startDate= new Date();
  issueDate: Date;
  dobString: string;
  stateCityList: StateCity[];
  error: boolean;
  certificateResponse: CertificateResponse;


  
  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private alertService: AlertService,
    private configDataService: ConfigDataService,
    private certificateService: CertificateService, 
    private excelBulkIndvTemplateService:ExcelBulkIndvTemplateService,
    private dealerService: DealerService,

  ) { }

  ngOnInit(): void {
    this.certficateForm = this.formBuilder.group({
      vehicleMake: [''],
      model: [''],
      type: [''],
      variant: [''],
      vehicleYear: [''],
      registrationNumber: [''],
      chasisNumber: [''],
      engineNumber: [''],
      clientType:[''],
      firstName: [''],
      middleName: [''],
      lastName: [''],
      vehicleType: ['Two Wheeler'],
      email: [''],
      contactNumber: [''],
      employeeContactNumber: [''],
      gender: [''],
      dob: [''],
      gstin: [''],
      address: [''],
      state: [''],
      city: [''],
      nomineeName: [''],
      nomineeRelationship: [''],
      nomineeGender: [''],
      nomineeAge: [''],
      planId: ['', Validators.required],
      planAmount: ['']
      , planGSTAmount: ['']
      , planTotalAmount: ['', [Validators.required,
      (control: AbstractControl) => Validators.max(this.maxAmount)(control)
        , (control: AbstractControl) => Validators.min(this.minAmount)(control)]]
      , startDate: ['', Validators.required]
      , endDate: ['']
      , issueDate: ['']
      , balance: ['']
      , userId: ['']
      , certificateNumber: ['']
      , policyNumber: ['']
      , financeCompanyName: ['']
      ,uploadFile:['']
      , financeCoverAmount: [50000]
    });

    this.startDateModel =  {"year":2010, "month": 1,"day": 1};
    this.startDateModel.year = this.currentDate.getFullYear();
    this.startDateModel.day = this.currentDate.getDate();
    this.startDateModel.month = this.currentDate.getMonth() + 1;
    this.configDataService.getTodaysDate()
    .subscribe(x => {
      this.currentDate = new Date(x);
      this.issueDate = new Date(x);
      this.startDate = new Date(x);
      this.endDate = new Date(new Date().setFullYear(this.currentDate.getFullYear() + 1));
      this.startDateModel.year = this.currentDate.getFullYear();
      this.startDateModel.day = this.currentDate.getDate();
      this.startDateModel.month = this.currentDate.getMonth() + 1;
      this.certficateForm.controls['startDate'].setValue(this.startDateModel);

    })
    this.configDataService.MakeModel$.subscribe(a =>
      {
        this.twoWheelerModels = a.filter(s => s.productType.includes('Two'));
        this.nonTwoWheelerModels = a.filter(s => !s.productType.includes('Two'));
      }

    )

    this.user = this.accountService.userValue;
    console.log(this.user.dealerId);
    this.configDataService.StateCity$.subscribe( (a) => {this.stateCityList = a;});
    this.certficateForm.controls['userId'].setValue(this.user.id);

    this.certficateForm.controls['planAmount'].disable();
    this.certficateForm.controls['planGSTAmount'].disable();
    this.certficateForm.controls['planTotalAmount'].disable();
    this.certficateForm.controls['endDate'].disable();
    this.certficateForm.controls['issueDate'].disable();
    this.certficateForm.controls['balance'].disable();
    this.certficateForm.controls['startDate'].disable();

    this.dealerService.getByDealerId(this.user.dealerId).subscribe((x) => {
      this.dealer = x;
      if (this.dealer.dealerMake.filter(a => a.make == "Honda Motorcycle & Scooter India").length > 0)
      {
        this.showHondaExcel = true;
      }
      else
      {
        this.showHondaExcel = false;
      }
      this.plans = x.dealerPlan;
      if (this.plans.length > 0)
      this.certficateForm.controls['planId'].setValue(this.plans[0].planId);
      this.certficateForm.controls['balance'].setValue(this.dealer.dealerBalance);


    });
    this.selectedPlan.numberOfYears = this.selectedPlan.numberOfYears? this.selectedPlan.numberOfYears : 1;
    
    this.certficateForm.get('planId').valueChanges
      .subscribe(value => {
        if (value != undefined) {
          this.selectedPlan = this.plans.filter((x) => x.planId == value)[0];
          //console.log(this.selectedPlan);
          this.certficateForm.controls['planAmount'].setValue(this.selectedPlan.planAmount);
          this.certficateForm.controls['planGSTAmount'].setValue(this.selectedPlan.planGstAmount);
          this.certficateForm.controls['planTotalAmount'].setValue(this.selectedPlan.planAmount + this.selectedPlan.planGstAmount);
          this.selectedPlan.numberOfYears = this.selectedPlan.numberOfYears? this.selectedPlan.numberOfYears : 1;
          this.maxAmount = this.selectedPlan.planMaximumAmount;
          this.minAmount = this.selectedPlan.planMinimumAmount? this.selectedPlan.planMinimumAmount : this.selectedPlan.planNetAmount;
          this.certficateForm.controls['planAmount'].updateValueAndValidity();
          this.certficateForm.controls['startDate'].setValue(this.startDateModel);
          this.endDate = new Date(this.startDate);
          this.endDate = new Date(this.endDate.setFullYear(this.endDate.getFullYear() + this.selectedPlan.numberOfYears));
          this.endDate = new Date(this.endDate.setDate(this.endDate.getDate() - 1));
          this.endDate = new Date(this.endDate.setHours(0, 0, 0, 0));
          this.certficateForm.controls['endDate'].setValue(this.endDate);
          this.certficateForm.controls['issueDate'].setValue(this.currentDate);
          this.showGstAmount = this.selectedPlan.gstApplicable ? this.selectedPlan.gstApplicable =="N" ? false : true : true; 
          this.showGstAmount = this.showGstAmount == false? false : this.selectedPlan.gstShow ? this.selectedPlan.gstShow =="N" ? false : true : true; 
          this.certficateForm.controls['uploadFile'].reset();
          if (this.selectedPlan.policyType == "FINANCE")
          {
          this.configDataService.FinanceCompanyList$.subscribe((x) => {
            this.financeCompanies = x;
            this.showFinance = true;
            this.certficateForm.get('financeCompanyName').setValidators(Validators.required);
            this.certficateForm.get('financeCoverAmount').setValidators(Validators.required);
            this.selectedPlan.planCPACoverAmount = this.selectedPlan.planCPACoverAmount?this.selectedPlan.planCPACoverAmount:500000;
            this.certficateForm.get('financeCoverAmount').setValidators(Validators.max(this.selectedPlan.planCPACoverAmount));
    
          });

          }
          else {
            this.showFinance = false;
            this.certficateForm.get('financeCompanyName').clearValidators();
            this.certficateForm.get('financeCoverAmount').clearValidators();
          }
          this.certficateForm.controls['financeCompanyName'].updateValueAndValidity();
          this.certficateForm.controls['financeCoverAmount'].updateValueAndValidity();

    
        }

        // if (value == "Flexible" || value == "Plan-CPA-DOC-AMEE" || this.selectedPlan.planId == "Plan-PAF") {
          if (this.selectedPlan.planAmount == 0) {
            this.certficateForm.controls['planTotalAmount'].enable();
        }
        else {
          this.certficateForm.controls['planTotalAmount'].disable();
        }
      });

      this.certficateForm.get('financeCompanyName').valueChanges.subscribe((value) => {
        if (value){
          this.nameRegexp = new RegExp(value,'i'); 
          this.selectedFinanceCompanies = this.financeCompanies.filter(a => {
          return this.nameRegexp.test(a);
        })}
        else
        this.selectedFinanceCompanies = [];
     });

     this.certficateForm.get('planTotalAmount').valueChanges
     .subscribe(value => {
       if (value != undefined) {
         // if (this.selectedPlan.planId == "Flexible" || this.selectedPlan.planId == "Plan-CPA-DOC-AMEE" || this.selectedPlan.planId == "Plan-PAF") {
           if (this.selectedPlan.planAmount == 0) {
             this.planTotalAmount = parseInt(value);
           this.planAmount = (this.planTotalAmount / (1 + this.gstPercentage)).toFixed(2);
           this.planGSTAmount = (this.planTotalAmount - this.planAmount).toFixed(2);
           this.minAmount = this.selectedPlan.planMinimumAmount? this.selectedPlan.planMinimumAmount :1000;
           // this.planGSTAmount = this.gSTAmount.toFixed(2);
           // this.planTotalAmount = this.planAmount + parseInt(this.planGSTAmount);
           this.certficateForm.controls['planGSTAmount'].setValue(this.planGSTAmount);
           this.certficateForm.controls['planAmount'].setValue(this.planAmount);
           this.balanceValidator();
         }
       }
     });
     this.certficateForm.get('startDate').valueChanges
     .subscribe(value =>{
       if (value != undefined){
         if(value.month != undefined && value.day != undefined && value.year != undefined){
             this.startDate = new Date(this.convertTextDate(value));
             if (parseInt(value.month) == (this.currentDate.getMonth() + 1) && parseInt(value.day) == this.currentDate.getDate() && parseInt(value.year) == this.currentDate.getFullYear())
             this.startDate = new Date(this.currentDate);
             else
             this.startDate = new Date(this.startDate.setHours(0, 0, 0, 0));

             //console.log("value : " + value);
             //console.log(this.currentDate.getFullYear() + " " + this.currentDate.getDay() + " " + this.currentDate.getDay());

             this.endDate = new Date(this.startDate);
             this.endDate = new Date(this.endDate.setFullYear(this.endDate.getFullYear() + this.selectedPlan.numberOfYears));
             this.endDate = new Date(this.endDate.setDate(this.endDate.getDate() - 1));
             this.endDate = new Date(this.endDate.setHours(0, 0, 0, 0));
             this.certficateForm.controls['endDate'].setValue(this.endDate);
             this.certficateForm.controls['issueDate'].setValue(this.startDate);
             this.issueDate = this.startDate;
                       }
       }

     })
   }
  get f() { return this.certficateForm.controls; }
  onSubmit(){
    this.submitted = true;
// reset alerts on submit
    this.alertService.clear();
    if (this.certficateForm.invalid) {
      return;
    }
    this.loading = true;

    var issueCertificate: Certificate;
    console.log(JSON.stringify(this.certficateForm.getRawValue()));
    issueCertificate = new Certificate(this.certficateForm.getRawValue());
    issueCertificate.issueDateString = this.issueDate.getFullYear() + "-" + ('0' + (this.issueDate.getMonth() + 1)).slice(-2) + "-" + ('0' + this.issueDate.getDate()).slice(-2);
    issueCertificate.startDate = this.certficateForm.get('issueDate').value;
    issueCertificate.planId = this.selectedPlan.planId;
    issueCertificate.printCpaPolicy = this.selectedPlan.printCpaPolicy?this.selectedPlan.printCpaPolicy:"Yes";

    issueCertificate.planCPACoverAmount = this.selectedPlan.planCPACoverAmount?this.selectedPlan.planCPACoverAmount : 1500000;
    issueCertificate.printRsaNumber = this.selectedPlan.printRsaNumber? this.selectedPlan.printRsaNumber: "No";
    if (this.selectedPlan.policyType == "FINANCE")
    {
    issueCertificate.nomineeCoverAmount = issueCertificate.planCPACoverAmount - issueCertificate.financeCoverAmount;
  }
  else
  {
    issueCertificate.nomineeCoverAmount = issueCertificate.planCPACoverAmount ;

  }

  this.certificates = this.excelBulkIndvTemplateService.certificates.map(c =>
    {
      c.employeeContactNumber = "NA";
      if (this.user.specialRoleAccess && this.user.specialRoleAccess.includes('canSeeAge'))
      {
        c.ageOrDob = 'age'
      }
      else
      {
        c.ageOrDob = 'dob'
      }
      c.planId = issueCertificate.planId;
      c.planAmount = issueCertificate.planAmount;
      c.planGSTAmount = issueCertificate.planGSTAmount;
      c.planTotalAmount = issueCertificate.planTotalAmount;
      c.startDate = issueCertificate.startDate;
      c.endDate = issueCertificate.endDate;
      c.issueDate = issueCertificate.issueDate;
      c.printCpaPolicy = issueCertificate.printCpaPolicy;
      c.financeCompanyName = issueCertificate.financeCompanyName;
      c.financeCoverAmount = issueCertificate.financeCoverAmount;
      c.planCPACoverAmount = issueCertificate.planCPACoverAmount;
      c.printRsaNumber = issueCertificate.printRsaNumber;
      c.nomineeCoverAmount = issueCertificate.nomineeCoverAmount;
      c.issueDateString = issueCertificate.issueDateString;
      c.userId = issueCertificate.userId;
      c.type = "New";
      c.clientType = "Individual";
      if (this.dealer.onePager)
        {
          c.onePager =  true;
        }
        else
        {
          c.onePager =  false;
        }
    
      return c;
    }
    )
    this.certificateService.issueBulkCertificate(this.certificates)
    .pipe(first())
    .subscribe({
      next: (x) => {
        this.certificateResponse = x;
        // this.dealer.dealerBalance = this.certificateResponse.dealer.dealerBalance;
        this.alertService.success('Certificate added successfully.  Please navigate to "Print Certificate" section to print certificates', { keepAfterRouteChange: true });
        this.loading = false;

      },
      error: error => {
        this.alertService.error(error);
        this.certficateForm.controls['uploadFile'].reset();
        this.submitted = false;
        this.loading = false;
      }
    });
    

  }
 async onFileChange(event:any){
    if (this.selectedPlan.policyType.includes('CAR'))
    {
     await this.excelBulkIndvTemplateService.uploadFile(event,this.dealer,this.nonTwoWheelerModels,this.selectedPlan, this.stateCityList)
  }
  else
  {
      await this.excelBulkIndvTemplateService.uploadFile(event,this.dealer,this.twoWheelerModels,this.selectedPlan, this.stateCityList)
  }
  this.error = this.excelBulkIndvTemplateService.error;
  if (this.error) this.certficateForm.controls['uploadFile'].reset();

  console.log("OutputCertificates: " + JSON.stringify(this.error));
  }
  dowloadTemplate(){
    this.excelBulkIndvTemplateService.downloadTemplateFile(this.dealer)
  }
  financeSelected(inputFinance:string)
  {
    this.certficateForm.controls["financeCompanyName"].setValue(inputFinance);
    this.selectedFinance = inputFinance;
  }

  balanceValidator() {
    if (this.certficateForm.get('planTotalAmount').value != undefined) {
      this.runningBalance = this.dealer.dealerBalance - parseInt(this.certficateForm.get('planTotalAmount').value)
      // console.log("Dealer Balance" + this.runningBalance);

      if (this.runningBalance < this.dealer.dealerBalanceLimit) {
        this.isBalanceLow = true;
      }
      else
        this.isBalanceLow = false;
    }
  }

  convertTextDate(value: NgbDateStruct) {
    if (value) {
      this.dobString = value.year.toString();
      if (value.month < 10) {
        this.dobString = this.dobString + "-0" + value.month;
      }
      else {
        this.dobString = this.dobString + "-" + value.month;
      }
      if (value.day < 10) {
        this.dobString = this.dobString + "-0" + value.day;
      }
      else {
        this.dobString = this.dobString + "-" + value.day;
      }
    }
    return this.dobString;
  }

}

